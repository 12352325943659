<template>
  <base-layout title="Communication">
    <ion-grid fixed>
      <ion-row>
        <ion-col size="12">
          <ion-list>
            <ion-item
              :router-link="{ name: p.routeName }"
              v-for="(p, i) in appPages"
              :key="i"
              class="link"
            >
              <ion-icon slot="start" :ios="p.iosIcon" :md="p.mdIcon"></ion-icon>
              <ion-label>
                <h2>{{ p.title }}</h2>
                <p>{{ p.description }}</p>
              </ion-label>
            </ion-item>
          </ion-list>
        </ion-col>
      </ion-row>
    </ion-grid>
  </base-layout>
</template>

<script>
import BaseLayout from "../../Base/BaseLayout";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonIcon,
  IonLabel,
} from "@ionic/vue";

import { bookOutline, bookSharp } from "ionicons/icons";

export default {
  components: {
    BaseLayout,
    IonGrid,
    IonRow,
    IonCol,
    IonList,
    IonItem,
    IonIcon,
    IonLabel,
  },
  data() {
    return {
      appPages: [
        {
          title: "Send Template SMS",
          description: "TRAI(DLT) approved SMS templates",
          routeName: "sms.template",
          iosIcon: bookOutline,
          mdIcon: bookSharp,
        },
        {
          title: "Send App Notification",
          description: "Send app notifications to users",
          routeName: "notification",
          iosIcon: bookOutline,
          mdIcon: bookSharp,
        },
        {
          title: "SMS Report",
          description: "Generate and download SMS report",
          routeName: "sms.report",
          iosIcon: bookOutline,
          mdIcon: bookSharp,
        },
        {
          title: "Sent Notifications",
          description: "View and delete app notifications",
          routeName: "notification.show",
          iosIcon: bookOutline,
          mdIcon: bookSharp,
        },
      ],
    };
  },
};
</script>

<style scoped>
</style>
